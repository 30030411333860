import React from "react"
import Layout from "../components/Layout"
import { Box, Heading, Text, Divider } from "@chakra-ui/core"

const JoinUsPage = () => (
  <Layout>
    <Box p={5} textAlign="center" h={400}>
      <Heading> Join Us </Heading>
      <Divider />
      <Text> Coming soon.. </Text>
    </Box>
  </Layout>
)
export default JoinUsPage
